* {
  background-color: #000;
  color: #fff;
}

h1 {
  text-align: center;
}

ul {
  list-style: none;
  padding-left: 0;
  text-align: center;
}

li {
  display: inline-block;
  margin-right: 3px;
}

img {
  max-width: 30vw;
}

img.product {
  max-width: 95vw;
}

p.center {
  text-align: center;
}